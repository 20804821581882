import { formatCurrency, formatDate, formatTime } from '@smarttransit/common'

import {
  getTransportationUserTransactionLog, salvagePayout, cannotSalvagePayout
} from '../../../../services/transportation-user-transaction-logs-service'
// import { findTransportationOwnerUsers } from '../../../../services/transportation-owner-users-service'

export default {
  name: 'transportation-accounts-payouts-salvage',
  props: {
    signedInUser: Object,
    selectedAccount: Object,
    agencies: Array
  },
  data () {
    return {
      apiInProgress: false,
      noSalvageApiInProgress: false,
      dateFrom: null,
      dateFromMenu: null,
      dateTo: null,
      dateToMenu: null,
      currentPayout: null,
      report: null,
      reportError: '',
      salvageOptions: {
        moneyAccountTypeId: '',
        callbackParameters: '',
        vendorProcessor: '',
        sendNotification: false
      },
      transportationUserMomoAccounts: []
    }
  },
  mounted: function () {
    this.$nextTick(async () => {
      if (this.$route.params.payoutId && this.$route.params.payoutId !== '0') {
        this.getCurrentSalvagePayout(this.$route.params.payoutId)
      }
      this.transportationUserMomoAccounts = this.$store.getters.getMoneyAccountTypes
    })
  },
  computed: {
    currentSelectedAccount () {
      return this.$props.selectedAccount
    },
    currentAgencies () {
      return this.$props.agencies
    },
    currentMomoAccountLabel () {
      if (this.currentPayout) {
        return this.currentPayout.transportationUserMomoAccount.transportationUserIdentifier.phoneNumber
      }
      return ''
    }
  },
  methods: {
    async getCurrentSalvagePayout (id) {
      try {
        this.apiInProgress = true
        this.currentPayout = await getTransportationUserTransactionLog({ id, filter: { include: { transportationUserMomoAccount: 'transportationUserIdentifier' } } })
        if (this.currentPayout.logStatus !== 'timedout') {
          const status = this.currentPayout.logStatus
          this.currentPayout = null
          throw new Error('Only timed out payouts can be salvaged, this report is marked: "' + status + '"')
        }
        this.report = this.marshallReport({
          [this.currentPayout.logMetadata.agencyId]: {
            agencyId: this.currentPayout.logMetadata.agencyId,
            fee: this.currentPayout.logMetadata.feePercentage,
            grossPayout: this.currentPayout.logMetadata.grossPayout,
            feeDeduction: this.currentPayout.logMetadata.feeDeduction,
            payout: this.currentPayout.amount,
            totalTrips: this.currentPayout.logMetadata.totalTrips,
            currencySymbol: this.currentPayout.logMetadata.currencySymbol,
            dateStart: this.currentPayout.logMetadata.dateStart,
            dateEnd: this.currentPayout.logMetadata.dateEnd
          }
        })
        this.salvageOptions.moneyAccountTypeId = this.currentPayout.transportationUserMomoAccount.moneyAccountTypeId
        // this.salvageOptions.callbackParameters = JSON.stringify({})
        this.apiInProgress = false
      } catch (err) {
        this.apiInProgress = false
        this.reportError = `Error retrieving payout: ${err && err.error ? err.error.message : (err && err.message ? err.message : JSON.stringify(err))}`
      }
    },
    marshallReport (report) {
      for (const key in report) {
        if (report[key]) {
          report[key].agencyLabel = this.$props.agencies.find(o => o.value === report[key].agencyId).text
          report[key].grossPayoutLabel = `${report[key].currencySymbol}${formatCurrency(report[key].grossPayout / 100)}`
          report[key].payoutLabel = `${report[key].currencySymbol}${formatCurrency(report[key].payout / 100)}`
          report[key].feeLabel = `${report[key].fee * 100}%`
          report[key].feeDeductionLabel = `${report[key].currencySymbol}${formatCurrency(report[key].feeDeduction / 100)}`
          report[key].dateStartLabel = report[key].dateStart ? formatDate(report[key].dateStart) : 'All pending payouts'
          report[key].dateEndLabel = report[key].dateEnd && formatDate(report[key].dateEnd) !== formatDate(report[key].dateStart) ? formatDate(report[key].dateEnd) : ''
          if (this.currentPayout) {
            report[key].dateUpdatedLabel = formatTime(this.currentPayout.dateUpdated) + ' ' + formatDate(this.currentPayout.dateUpdated)
          }
        }
      }
      return report
    },
    async salvagePayout () {
      let parsedCallbackParameters
      try {
        if (!this.salvageOptions.callbackParameters) {
          throw new Error('Callback parameters required')
        }
        parsedCallbackParameters = JSON.parse(this.salvageOptions.callbackParameters.trim())
        if (!Object.keys(parsedCallbackParameters).length) {
          throw new Error('No fields provided for callback parameters')
        }
      } catch (err) {
        alert('Error parsing callback parameters: ' + err.message)
        return
      }
      if (confirm('Please confirm salvaging payout')) {
        try {
          this.apiInProgress = true
          const result = await salvagePayout({
            id: this.currentPayout.id,
            callbackParameters: parsedCallbackParameters,
            moneyAccountTypeId: this.salvageOptions.moneyAccountTypeId,
            vendorProcessor: this.salvageOptions.vendorProcessor,
            doNotSendNotification: !this.salvageOptions.sendNotification
          })
          this.apiInProgress = false
          if (result.logStatus === 'completed') {
            alert('SUCCESSFULLY salvaged')
            this.$router.push({ name: 'transportation-accounts-payouts-payout', params: { payoutId: this.currentPayout.id } })
          } else {
            throw new Error('Error trying to complete the process')
          }
        } catch (err) {
          this.apiInProgress = false
          this.reportError = `Error in salvage: ${err && err.error ? err.error.message : (err && err.message ? err.message : JSON.stringify(err))}`
        }
      }
    },
    async cannotSalvagePayout () {
      if (confirm('Are you sure you want to mark this un-salvageable?')) {
        try {
          this.noSalvageApiInProgress = true
          await cannotSalvagePayout({ id: this.currentPayout.id })
          alert('Marked as "Cannot Salvage"')
          this.$router.push({ name: 'transportation-accounts-payouts' })
          // this.getCurrentSalvagePayout(this.currentPayout.id)
          this.noSalvageApiInProgress = false
        } catch (err) {
          this.noSalvageApiInProgress = false
          this.reportError = `Error in cannot salvage: ${err && err.error ? err.error.message : (err && err.message ? err.message : JSON.stringify(err))}`
        }
      }
    },
    onFilterByAgency (val) {
      this.selectedAgency = val
    }
  }
}
