<template>
  <div class="transportation-accounts-payouts-payout">
    <div v-if="$router.currentRoute.name === 'transportation-accounts-payouts-salvage'" class="mx-2">
        <div class="layout column align-center justify-center mt-2 mb-5">
          <v-progress-circular v-if="apiInProgress && !report" indeterminate :size="50" color="primary" />
          <v-alert type="warning" v-show="!reportError && !report">
            No report found
          </v-alert>
          <div v-if="report" class="layout row justify-space-between align-start wrap">
            <div class="mr-2">
              <div class="mb-2">
                <h2 class="text-xs-center">Payout Report</h2>
                <div class="text-xs-center">Transaction ID: <small>{{currentPayout.id}}</small></div>
              </div>
              <div v-if="report">
                <div v-for="(reportItem, key) in report" :key="key" class="elevation-1" style="width: 500px">
                <v-layout row align-center>
                  <v-flex sm5 pa-2 class="text-xs-right">
                    <strong>Agency</strong>
                  </v-flex>
                  <v-flex sm7 pa-2>
                    {{reportItem.agencyLabel}}
                  </v-flex>
                </v-layout>
                <v-layout row align-center v-if="currentPayout">
                  <v-flex sm5 pa-2 class="text-xs-right">
                    <strong>Paid out to</strong>
                  </v-flex>
                  <v-flex sm7 pa-2>
                    {{currentPayout.transportationUserMomoAccount.transportationUserIdentifier.phoneNumber}}
                     <v-chip small>{{currentPayout.transportationUserMomoAccount.moneyAccountTypeId}}</v-chip>
                  </v-flex>
                </v-layout>
                <v-layout row align-center>
                  <v-flex sm5 pa-2 class="text-xs-right">
                    <strong>Gross amount</strong>
                  </v-flex>
                  <v-flex sm7 pa-2>
                    {{reportItem.grossPayoutLabel}}
                  </v-flex>
                </v-layout>
                <v-layout row align-center>
                  <v-flex sm5 pa-2 class="text-xs-right">
                    <strong>Fees</strong>
                  </v-flex>
                  <v-flex sm7 pa-2>
                    - {{reportItem.feeDeductionLabel}}
                    <v-chip small>
                      {{reportItem.feeLabel}}
                    </v-chip>
                  </v-flex>
                </v-layout>
                <v-layout row align-center>
                  <v-flex sm5 pa-2 class="text-xs-right">
                    <strong>Amount</strong>
                  </v-flex>
                  <v-flex sm7 pa-2>
                    <v-chip :color="currentPayout && currentPayout.logStatus === 'completed' ? 'success' : 'default'" :text-color="currentPayout && currentPayout.logStatus === 'completed' ? '#fff' : ''">
                      <v-icon small>fal fa-envelope-open-dollar</v-icon> &nbsp;
                      {{reportItem.payoutLabel}}
                    </v-chip>
                  </v-flex>
                </v-layout>
                <v-layout row align-center v-if="currentPayout">
                  <v-flex sm5 pa-2 class="text-xs-right">
                    <v-icon small color="warning">far fa fa-hourglass-end</v-icon> &nbsp; <strong>Timed
                    out at</strong>
                  </v-flex>
                  <v-flex sm7 pa-2>
                    {{reportItem.dateUpdatedLabel}}
                  </v-flex>
                </v-layout>
                <v-layout row align-center>
                  <v-flex sm5 pa-2 class="text-xs-right">
                    <strong>Purchases Date / Date Range</strong>
                  </v-flex>
                  <v-flex sm7 pa-2>
                    {{reportItem.dateStartLabel}}<span v-if="reportItem.dateEndLabel"> to {{reportItem.dateEndLabel}}</span>
                  </v-flex>
                </v-layout>
                <v-layout row align-center>
                  <v-flex sm5 pa-2 class="text-xs-right">
                    <strong>Purchases for this Period</strong>
                  </v-flex>
                  <v-flex sm7 pa-2>
                    {{reportItem.totalTrips}}
                  </v-flex>
                </v-layout>
                <hr class="report-segment" />
              </div>
              </div>
            </div>
            <div class="ml-2">
              <div class="mb-2">
                <h2 class="text-xs-center">Salvage Settings</h2>
                <div class="text-xs-center">
                  <h3 v-show="currentPayout.logMetadata.notSalvagedByStUser"><v-icon color="warning">fa fa-ban</v-icon> &nbsp;Marked as "Cannot Salvage"</h3>
                  <span v-show="!currentPayout.logMetadata.notSalvagedByStUser">&nbsp;</span>
                </div>
              </div>
              <div class="elevation-1" style="width: 500px">
                <v-layout row align-center>
                  <v-flex sm5 pa-2 class="text-xs-right">
                    <strong>Mobile money account</strong>
                  </v-flex>
                  <v-flex sm7 pa-2>
                    <v-select
                        hide-details
                        v-model="salvageOptions.moneyAccountTypeId"
                        :items="transportationUserMomoAccounts"
                        label="Mobile money accounts"
                        outline
                    >
                      <template slot="item" slot-scope="{ item }">
                        <div class="layout row justify-start align-center">
                          <div>{{item.text}}</div>
                          <div class="ml-2">
                            <img v-if="item.metadata.src" :src="item.metadata.src" style="height: 30px;" :alt="item.metadata.moneyAccountTypeId" />
                            <small v-if="!item.metadata.src">({{item.metadata.moneyAccountTypeId}})</small>
                          </div>
                        </div>
                      </template>
                      <template slot="selection" slot-scope="{ item }">
                        <div class="layout row justify-start align-center">
                          <div>{{item.text}}</div>
                          <div class="ml-2">
                            <img v-if="item.metadata.src" :src="item.metadata.src" style="height: 30px;" :alt="item.metadata.moneyAccountTypeId" />
                            <small v-if="!item.metadata.src">({{item.metadata.moneyAccountTypeId}})</small>
                          </div>
                        </div>
                      </template>
                    </v-select>
                  </v-flex>
                </v-layout>
                <v-layout row align-start>
                  <v-flex sm5 pa-2 class="text-xs-right">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon small v-on="on">fa fa-info-circle</v-icon>
                      </template>
                      <span>The parameters object that should have succeeded, this is what needs to be modified to attempt to complete the payout</span>
                    </v-tooltip> &nbsp; <strong>Callback parameters</strong>
                  </v-flex>
                  <v-flex sm7 pa-2>
                    <v-textarea
                      style="font-size: 0.8em; font-family: monospace, monospace;"
                      outline
                      auto-grow
                      hide-details
                      label="Make sure to use double quotes"
                      name="callback_parameters"
                      placeholder="Copy and paste code from email or vendor processor examples below"
                      v-model="salvageOptions.callbackParameters" />
                  </v-flex>
                </v-layout>
                <v-layout row align-start>
                  <v-flex sm5 pa-2 class="text-xs-right">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon small v-on="on">fa fa-info-circle</v-icon>
                      </template>
                      <span>Only set this if you want to force usage of a specific service different from what the server's default is.<br />Depending on the callback parameters, the service processing the payout may be different</span>
                    </v-tooltip> &nbsp;
                    <strong>Vendor processor</strong>
                  </v-flex>
                  <v-flex sm7 pa-2>
                    <v-select
                        clearable
                        outline
                        hide-details
                        v-model="salvageOptions.vendorProcessor"
                        :items="[{text: 'Appsnmobile', value: 'appsnmobile'}, {text: 'ITC Services', value: 'mtn'}]"
                        label="Force use of Vendor Processor"
                    />
                    <div>
                      <small>
                        Appsnmobile uses the callback parameters example:<br />
                        <code>{"message": "Successfully processed transaction.","trans_status": "000/**","trans_id": "","trans_ref": ""}</code>
                      </small>
                    </div>
                    <div>
                      <small>
                        ITC Services uses the callback parameters example:<br />
                        <code>{"responseCode": "01", "responseMessage": "message", "uniwalletTransactionId": "", "networkTransactionId": "", "merchantId": "1432", "productId": "72", "refNo": "", "msisdn": "&lt;phone number without '+' symbol&gt;", "amount": ""}</code>
                      </small>
                    </div>
                  </v-flex>
                </v-layout>
                <v-layout row align-center>
                  <v-flex sm5 pa-2 class="text-xs-right">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon small v-on="on">fa fa-info-circle</v-icon>
                      </template>
                      <span>Notify the payee with the original email/SMS text they would have received if the payout had been completed</span>
                    </v-tooltip> &nbsp; <strong>Send notification</strong>
                  </v-flex>
                  <v-flex sm7 pa-2>
                    <v-switch v-model="salvageOptions.sendNotification" label="Notify user if successful" />
                  </v-flex>
                </v-layout>
              </div>
            </div>
          </div>
          <v-alert type="warning" class="mt-2" v-show="reportError">
            {{reportError}}
          </v-alert>
          <div v-if="report" class="mt-2">
            <v-btn color="primary" :loading="apiInProgress" :disabled="apiInProgress || noSalvageApiInProgress" @click="salvagePayout()">
              <v-icon small>fa fa-trash-undo-alt</v-icon> &nbsp; Salvage &nbsp; {{currentMomoAccountLabel}}
            </v-btn> <span v-show="!currentPayout.logMetadata.notSalvagedByStUser">&nbsp;
            <v-btn class="warning" :loading="noSalvageApiInProgress" :disabled="noSalvageApiInProgress" @click="cannotSalvagePayout()">
              <v-icon small>fa fa-ban</v-icon> &nbsp; Cannot Salvage
            </v-btn></span>
          </div>
        </div>
    </div>
  </div>
</template>

<style scoped>
  .report-segment:last-child {
    display: none
  }
</style>

<script>
import src from './src'
export default src
</script>
